<template>
  <button @click="closeModal">
    <Close />
  </button>
</template>

<script setup lang="ts">
import Close from '~/assets/svg/Close.svg'

const { closeModal } = useModal()
</script>
