export function useFormValidation() {
  return {
    validateEmailInput: (email: string): string => {
      if (email === '') {
        return 'Bitte gib eine Email-Adresse ein'
      }

      const emailPattern = /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/
      if (!emailPattern.test(email)) {
        return 'Bitte gib eine gültige E-Mail Adresse ein'
      }

      return ''
    },
    validateCusnoInput: (cusno: string | number): string => {
      if (cusno === '') {
        return 'Bitte gib deine Abonummer ein'
      }

      if (Number.isNaN(Number(cusno))) {
        return 'Bitte gib eine gültige Abonummer ein'
      }

      return ''
    },
    validateEmptyPasswordInput: (password: string) => {
      if (password === '') {
        return 'Bitte gib ein Passwort ein'
      }

      return ''
    },
    validatePasswordInput: (password: string) => {
      if (password === '') {
        return 'Bitte gib ein Passwort ein'
      }

      if (password.length < 6) {
        return 'Bitte wähle ein Passwort mit mind. 6 Zeichen'
      }

      if (/\s/.test(password)) {
        return 'Das Passwort darf keine Leerzeichen enthalten'
      }

      return ''
    },
    validateEmailOrUsernameInput: (emailOrUsername: string): string => {
      if (emailOrUsername === '') {
        return 'Bitte gib eine E-Mail Adresse oder einen Benutzernamen ein'
      }

      return ''
    },
  }
}
